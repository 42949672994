@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


@layer base {
  body {
    @apply font-sans dark:bg-[#0d1b33] dark:text-gray-50 bg-[#4a57ec] text-gray-200 w-full h-full m-0 p-0 overflow-x-hidden
  }
}

@layer components {
  .accordion-tab {
    @apply transition duration-500 w-[19rem] md:w-full text-gray-50 lg:text-lg font-medium group-hover:text-amber-400 dark:group-hover:text-emerald-400
  }
  .accordion-arrow {
    @apply transition duration-500 h-9 w-9 group-hover:text-amber-400 dark:group-hover:text-emerald-400 
  }
  .accordion-content {
    @apply overflow-hidden transition duration-500 max-h-0
  }
}

.input-shadow {
  box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.8) inset;
  -webkit-box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.8) inset;
  -moz-box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.8) inset;
}

.out-container {
  position: relative;
  flex-wrap: wrap;
  z-index: 1;
}
.inner-card {
  position: relative;
  width: 22.5rem;
  height: 37.5rem;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: rgba(255,255,255,0.11);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255,255,255,0.5);
  border-left: 1px solid rgba(255,255,255,0.5);
  backdrop-filter: blur(5px);

}
.out-container .inner-card .card-contents {
  transform: translateY(-5px);
  transition: 0.5s;
}
.out-container .inner-card:hover .card-contents {
  transform: translateY(-15px);
}


.typing-effect {
  width: 29ch;
  animation: typing 2.5s steps(22), blink .4s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}
@keyframes typing {
  from {
    width: 0
  }
}
@keyframes blink {
  50% {
    border-color: transparent
  }
}















/* @Reusable! -- Hamburger Button Starts -- */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  transition: all 0.25s;
}
.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
position: absolute;
width: 26px;
height: 2px;
top: 0;
left: 0;
background: #fff;
transform: rotate(0);
transition: all 0.5s;
padding-top:3px;
}
.hamburger-middle {
transform: translateY(7px);
}
.hamburger-bottom {
transform: translateY(14px);
}
.open {
transform: rotate(90deg);
}
.open .hamburger-top {
transform: rotate(45deg) translateY(6px) translateX(6px);
}
.open .hamburger-middle {
display: none;
}
.open .hamburger-bottom {
transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
/* @Reusable! -- Hamburger Button Ends -- */

/* @Reusable! -- Small Icon Hover Color Change -- */
.ficon:hover {
  filter: invert(79%) sepia(61%) saturate(464%) hue-rotate(130deg)
    brightness(89%) contrast(85%);
}
/* @Reusable! -- Small Icon Hover Color Change -- */


/* @Reusable! -- Loading Page Starts -- */

.loading-bg {
  --hue: 223;
	--bg: hsl(var(--hue),10%,90%);
	--fg: hsl(var(--hue),10%,10%);
	font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.999;
  z-index: 9;

}

.loading-body {
	color: var(--fg);
	font: 1em/1.5 sans-serif;
	height: 100%;
	transition: background-color 0.3s;
}

.pl,
.pl__worm {
	animation-duration: 3s;
	animation-iteration-count: infinite;
}
.pl {
	animation-name: bump;
	animation-timing-function: linear;
	width: 9rem;
	height: 9rem;
}
.pl__ring {
	stroke: hsla(var(--hue),10%,10%,0.1);
	transition: stroke 0.3s;
}
.pl__worm {
	animation-name: worm;
	animation-timing-function: cubic-bezier(0.42,0.17,0.75,0.83);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	.loading-bg {
		--bg: hsl(var(--hue),10%,10%);
		--fg: hsl(var(--hue),10%,90%);
	}
	.pl__ring {
		stroke: hsla(var(--hue),10%,90%,0.25);
	}
}
/* animation */
@keyframes bump {
	from,
	42%,
	46%,
	51%,
	55%,
	59%,
	63%,
	67%,
	71%,
	74%,
	78%,
	81%,
	85%,
	88%,
	92%,
	to { transform: translate(0,0); }
	44% { transform: translate(1.33%,6.75%); }
	53% { transform: translate(-16.67%,-0.54%); }
	61% { transform: translate(3.66%,-2.46%); }
	69% { transform: translate(-0.59%,15.27%); }
	76% { transform: translate(-1.92%,-4.68%); }
	83% { transform: translate(9.38%,0.96%); }
	90% { transform: translate(-4.55%,1.98%); }
}
@keyframes worm {
	from { stroke-dashoffset: 10; }
	25% { stroke-dashoffset: 295; }
	to { stroke-dashoffset: 1165; }
}

/* @Reusable! -- Loading Page Ends -- */

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}





